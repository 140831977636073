<template>
  <div>
    <div class="_BoxHeader">
      <span @click="beforeunloadFn()">题库导入</span>
    </div>
    <div class="PageContain" v-loading="loading">
      <div class="_SelectHeader">
        <span class="_Title">列表信息</span>
        <div v-show="!isNotPurview">
          <el-button type="primary" @click="uploadFile">导入</el-button>
          <el-button type="primary" @click="surePost">确认上传</el-button>
        </div>
      </div>
      <input
        ref="fileInput"
        type="file"
        @change="importFile(this)"
        id="imFile"
        style="display: none"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <div class="contain-box" :style="{ height: elementHeight + 'px' }">
        <div>
          <el-table
            :data="tableList"
            :height="elementHeight - 20"
            style="width: auto"
            :header-cell-style="{
              background: '#E8EBF4',
              color: '#5C5C5C',
              borderColor: '#C0C0C0',
            }"
            border
            @cell-dblclick="toChange"
            @select="selectRow"
            :key="undo"
            :cell-class-name="tableCellClassName"
            id="table"
          >
            <el-table-column type="selection" width="80" v-if="isUse">
            </el-table-column>
            <el-table-column prop="compName" label="公司" width="180">
            </el-table-column>
            <el-table-column prop="deptName" label="部门" width="120">
            </el-table-column>
            <el-table-column prop="postName" label="岗位 " width="120">
            </el-table-column>
            <el-table-column prop="functionName" label="职能" width="120">
            </el-table-column>
            <el-table-column prop="fileNo" label="文件编号" width="120">
            </el-table-column>
            <el-table-column prop="fileName" label="文件名称">
            </el-table-column>
            <el-table-column prop="interpretation" label="应知应会" width="250">
            </el-table-column>
            <el-table-column prop="question" label="检查内容" width="180">
            </el-table-column>
            <el-table-column prop="auditMethod" label="审核方式" width="180">
            </el-table-column>
            <el-table-column prop="answer" width="200" label="标准要求">
            </el-table-column>
            <el-table-column
              prop="examQuestion"
              label="判断题（答案是）"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="disturbQuestion"
              label="判断题（答案否）"
              width="200"
            >
            </el-table-column>
            <el-table-column prop="choiceQue" label="选择题" width="200">
            </el-table-column>
            <el-table-column
              prop="choiceQueOption"
              label="选择题答案"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="choiceQueAnswer"
              label="选择题正确答案"
              width="80"
            >
            </el-table-column>
            <el-table-column prop="riskName" label="风控流程" width="200">
            </el-table-column>
            <el-table-column prop="riskPoint" label="风险点" width="200">
            </el-table-column>
            <el-table-column
              prop="riskResponse"
              label="风险应对措施"
              width="200"
            >
            </el-table-column>
            <el-table-column prop="version" label="版本号" width="60">
            </el-table-column>
            <el-table-column
              prop=""
              align="center"
              label="检查类别"
              width="680"
            >
              <el-table-column prop="" label="1">
                <el-table-column prop="areaAudit" label="现场检查" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="2">
                <el-table-column prop="fileAudit" label="文件检查" width="80">
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="isCurrency" label="通用" width="80">
            </el-table-column>
            <el-table-column prop="isFuncCurrency" label="职能通用" width="80">
            </el-table-column>
            <el-table-column prop="isSpecial" label="专指" width="80">
            </el-table-column>
            <el-table-column prop="isRisk" label="风控问题" width="80">
            </el-table-column>
            <el-table-column prop="" align="center" label="所在区域">
              <el-table-column prop="" label="1">
                <el-table-column prop="__EMPTY_0" label="厂区区域" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="2">
                <el-table-column prop="__EMPTY_1" label="车间区域" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="3">
                <el-table-column prop="__EMPTY_2" label="仓库区域" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="4">
                <el-table-column prop="__EMPTY_3" label="实验室区域" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="5">
                <el-table-column prop="__EMPTY_4" label="办公区域" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="6">
                <el-table-column prop="__EMPTY_5" label="饭堂区域" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="7">
                <el-table-column prop="__EMPTY_6" label="宿舍区域" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="8">
                <el-table-column prop="__EMPTY_7" label="工程区域" width="80">
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column
              prop=""
              align="center"
              label="检查范畴"
              width="auto"
            >
              <el-table-column prop="" label="1">
                <el-table-column prop="__EMPTY_01" label="安全生产" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="2">
                <el-table-column
                  prop="__EMPTY_8"
                  label="领导干部现场带班"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="3">
                <el-table-column
                  prop="__EMPTY_9"
                  label="三违行为管理"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="4">
                <el-table-column
                  prop="__EMPTY_10"
                  label="班组岗位达标安全"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="5">
                <el-table-column
                  prop="__EMPTY_11"
                  label="安全教育作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="6">
                <el-table-column prop="__EMPTY_12" label="交叉作业" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="7">
                <el-table-column
                  prop="__EMPTY_13"
                  label="相关方施工安全"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="8">
                <el-table-column
                  prop="__EMPTY_14"
                  label="特种作业人员管理"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="9">
                <el-table-column
                  prop="__EMPTY_15"
                  label="特种设备管理"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="10">
                <el-table-column
                  prop="__EMPTY_16"
                  label="危险作业工作票作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="11">
                <el-table-column prop="__EMPTY_17" label="动火作业" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="12">
                <el-table-column prop="__EMPTY_18" label="高空作业" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="13">
                <el-table-column prop="__EMPTY_19" label="吊装作业" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="14">
                <el-table-column
                  prop="__EMPTY_20"
                  label="有限空间作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="15">
                <el-table-column
                  prop="__EMPTY_21"
                  label="搬运安全作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="16">
                <el-table-column
                  prop="__EMPTY_22"
                  label="电焊机安全作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="17">
                <el-table-column
                  prop="__EMPTY_23"
                  label="气焊机安全作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="18">
                <el-table-column
                  prop="__EMPTY_24"
                  label="砂轮机安全作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="19">
                <el-table-column
                  prop="__EMPTY_25"
                  label="手持电动工具安全作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="20">
                <el-table-column
                  prop="__EMPTY_26"
                  label="起重机安全作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="21">
                <el-table-column
                  prop="__EMPTY_27"
                  label="液氨泄漏探测仪检测作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="22">
                <el-table-column
                  prop="__EMPTY_28"
                  label="液氨使用作业"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="23">
                <el-table-column prop="__EMPTY_29" label="涉水作业" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="24">
                <el-table-column
                  prop="__EMPTY_30"
                  label="危险化学品"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="25">
                <el-table-column
                  prop="__EMPTY_31"
                  label="一般化学品"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="26">
                <el-table-column prop="__EMPTY_32" label="安全用电" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="27">
                <el-table-column prop="__EMPTY_33" label="临时用电" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="28">
                <el-table-column
                  prop="__EMPTY_34"
                  label="职业健康安全"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="29">
                <el-table-column prop="__EMPTY_35" label="劳动防护" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="30">
                <el-table-column prop="__EMPTY_36" label="施工安全" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="31">
                <el-table-column prop="__EMPTY_37" label="消防设施" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="32">
                <el-table-column prop="__EMPTY_38" label="消防设备" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="33">
                <el-table-column prop="__EMPTY_39" label="疏散指示" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="34">
                <el-table-column prop="__EMPTY_40" label="特种设备" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="35">
                <el-table-column prop="__EMPTY_41" label="机械设备" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="36">
                <el-table-column prop="__EMPTY_42" label="投加设备" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="37">
                <el-table-column
                  prop="__EMPTY_43"
                  label="高低压电柜"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="38">
                <el-table-column prop="__EMPTY_44" label="一般设备" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="39">
                <el-table-column prop="__EMPTY_45" label="作业环境" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="40">
                <el-table-column prop="__EMPTY_46" label="办公环境" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="41">
                <el-table-column
                  prop="__EMPTY_47"
                  label="实验室环境"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="42">
                <el-table-column prop="__EMPTY_48" label="车间环境" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="43">
                <el-table-column prop="__EMPTY_49" label="仓库环境" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="44">
                <el-table-column prop="__EMPTY_50" label="宿舍环境" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="45">
                <el-table-column prop="__EMPTY_51" label="应急物资" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="46">
                <el-table-column prop="__EMPTY_52" label="应急设备" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="47">
                <el-table-column prop="__EMPTY_53" label="应急预案" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="48">
                <el-table-column prop="__EMPTY_54" label="应急演练" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="49">
                <el-table-column prop="__EMPTY_55" label="7S管理" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="50">
                <el-table-column prop="__EMPTY_56" label="设备状态" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="51">
                <el-table-column
                  prop="__EMPTY_57"
                  label="安全警示标识"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="52">
                <el-table-column
                  prop="__EMPTY_58"
                  label="作业指导书标识"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="53">
                <el-table-column prop="__EMPTY_59" label="行为标识" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="54">
                <el-table-column prop="__EMPTY_60" label="信息栏" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="55">
                <el-table-column
                  prop="__EMPTY_61"
                  label="卫生用品存放"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="56">
                <el-table-column prop="__EMPTY_62" label="四色图" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="57">
                <el-table-column prop="__EMPTY_63" label="疫情防控" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="58">
                <el-table-column
                  prop="__EMPTY_64"
                  label="管道安装工程质量"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="59">
                <el-table-column prop="__EMPTY_65" label="疫情防控" width="80">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="60">
                <el-table-column
                  prop="__EMPTY_66"
                  label="构筑物工程质量"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="61">
                <el-table-column
                  prop="__EMPTY_67"
                  label="道路工程质量"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="62">
                <el-table-column
                  prop="__EMPTY_68"
                  label="设备安装工程质量"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="63">
                <el-table-column
                  prop="__EMPTY_69"
                  label="机电安装工程质量"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column prop="" label="64">
                <el-table-column
                  prop="__EMPTY_70"
                  label="水环境治理工程质量"
                  width="80"
                >
                </el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Dialog
      title="查看错误"
      :showDialog="showErrorDialog"
      width="80%"
      showTowText="true"
      @handleClose="handleClose"
      @handleReqData="handleClose"
      top="1vh"
      class="error-dialog"
    >
      <el-table
        :data="tableList2"
        :height="elementHeight"
        style="width: 100%"
        border
        id="table"
        tooltip-effect="dark custom-tooltip-effect"
      >
        <el-table-column prop="fileNo" label="文件名" width="320">
        </el-table-column>
        <el-table-column prop="saveTime" label="导入时间" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime.split('T')[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="errorLog" label="错误信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="clickText(scope.row.errorLog)">{{
              scope.row.errorLog
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </Dialog>
    <Dialog
      title="错误信息"
      :showDialog="showErrorText"
      width="55%"
      showTowText="true"
      @handleClose="showErrorText = false"
      @handleReqData="showErrorText = false"
      top="5vh"
      class="error-dialog"
    >
      <div class="error-text">
        <span>{{ errorText }}</span>
      </div>
    </Dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { get, post } from '../../api/http'
import Dialog from '../../components/dialog/Dialog.vue'
import { objectSpanMethod } from '../../modules/TableConflate'

export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      loading: false,
      tableList: [],
      showErrorDialog: false,
      tableList2: [],
      errorText: '',
      excelName: '',
      showErrorText: false,
      isNotPurview: false,
      reqList: [],
      propertyList: [],
      dataList: [],
      run: true,
      wb: '',
      arrAreaName: ['厂区区域'],
      arrAuditTypeName: ['安全生产'],
      postAarrArea: [],
      postArrAuditType: [],
    }
  },

  mounted() {
    this.imFile = document.getElementById('imFile')
    this.getElementHeight()
    var roleName = JSON.parse(
      sessionStorage.getItem('userInfo')
    ).roleName.split(',')
    if (
      roleName.indexOf('股份公司管理员') == -1 &&
      roleName.indexOf('公司管理员') == -1 &&
      roleName.indexOf('部门管理员') == -1
    ) {
      this.isNotPurview = true
    }
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 100)
      })
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      return objectSpanMethod(rowIndex, columnIndex, this.tableList)
    },
    // 导入excel
    uploadFile: function () {
      // 点击导入按钮
      this.$refs.fileInput.value = null
      this.imFile.click()
    },
    importFile: function () {
      let obj = this.imFile
      if (!obj.files) {
        return
      }
      // this.loading = true
      this.tableList = []
      var f = obj.files[0]
      var reader = new FileReader()
      let $this = this
      reader.onload = function (e) {
        var data = e.target.result
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: 'base64',
          })
        } else {
          $this.wb = XLSX.read(data, {
            type: 'binary',
          })
        }
        // console.log($this.wb.Sheets[$this.wb.SheetNames[0]])
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        )
        var stopExecution = false
        console.log(excelData)
        excelData.map((item, index) => {
          if (index == 1) {
            for (var a = 1; a < 8; a++) {
              $this.arrAreaName.push(item['__EMPTY_' + a])
            }
            for (var b = 8; b < 71; b++) {
              $this.arrAuditTypeName.push(item['__EMPTY_' + b])
            }
          }
          if (stopExecution) return
          // 第一和第二行为标题的情况下不导入
          if (
            (index == 0 || index == 1) &&
            !Object.prototype.hasOwnProperty.call(item, '公司')
          )
            return
          if (
            //从exal表第一行开始必须要有公司
            !Object.prototype.hasOwnProperty.call(item, '公司') &&
            index == 2
          ) {
            return (stopExecution = true)
          }
          // 处理合并的数据
          if (!Object.prototype.hasOwnProperty.call(item, '公司')) {
            item.公司 = excelData[index - 1].公司
          }
          if (!Object.prototype.hasOwnProperty.call(item, '部门')) {
            item.部门 = excelData[index - 1].部门
          }
          if (!Object.prototype.hasOwnProperty.call(item, '岗位')) {
            item.岗位 = excelData[index - 1].岗位
          }
          if (!Object.prototype.hasOwnProperty.call(item, '职能')) {
            item.职能 = excelData[index - 1].职能
          }
          if (!Object.prototype.hasOwnProperty.call(item, '文件编号')) {
            item.文件编号 = excelData[index - 1].文件编号
          }
          if (!Object.prototype.hasOwnProperty.call(item, '文件名称')) {
            item.文件名称 = excelData[index - 1].文件名称
          }
          if (!Object.prototype.hasOwnProperty.call(item, '版本号')) {
            item.版本号 = excelData[index - 1].版本号
          }
          var data = {
            compName: item.公司,
            deptName: item.部门,
            postName: item.岗位,
            functionName: item.职能,
            fileNo: item.文件编号,
            fileName: item.文件名称,
            interpretation: item.应知应会,
            question: item.检查内容,
            auditMethod: item.审核方式,
            answer: item.标准要求,
            examQuestion: item['判断题（答案是）'],
            disturbQuestion: item['判断题（答案否）'],
            choiceQue: item.选择题,
            choiceQueOption: item.选择题答案,
            choiceQueAnswer: item.选择题正确答案,
            riskName: item.风控流程,
            riskPoint: item.风险点,
            riskResponse: item.风险应对措施,
            version: item.版本号,
            module: item.检查模块,
            arrAuditType: 'null',
            arrArea: 'null',
            isCurrency: item.通用 ? '√' : '',
            isFuncCurrency: item.职能通用 ? '√' : '',
            isSpecial: item.专指 ? '√' : '',
            isRisk: item.风控问题 ? '√' : '',
            areaAudit: item.检查类别 ? '√' : '', //现场检查
            fileAudit: item.__EMPTY ? '√' : '', //文件检查
            __EMPTY_0: item.所在区域 ? '√' : '', //厂区区域
            __EMPTY_01: item.检查范畴 ? '√' : '', //生产安全
          }
          // 所在区域
          for (var i = 1; i < 8; i++) {
            data['__EMPTY_' + i] = item['__EMPTY_' + i] ? '√' : ''
          }
          //检查范畴
          for (var e = 8; e < 71; e++) {
            data['__EMPTY_' + e] = item['__EMPTY_' + e] ? '√' : ''
          }
          $this.tableList.push(data)
        })
        if ($this.tableList.length != 0) {
          $this.importList(obj.files[0].name.split('.')[0])
        } else {
          console.log('格式不符合的数据=', excelData)
          $this.$message({
            type: 'warning',
            message: '导入文件不符合',
          })
          $this.loading = false
        }
      }
      if (this.rABS) {
        reader.readAsArrayBuffer(f)
      } else {
        reader.readAsBinaryString(f)
      }
    },

    importList(excelName) {
      this.dataList = []
      this.tableList.map((item) => {
        this.postAarrArea = []
        this.postArrAuditType = []
        if (item.__EMPTY_01)
          this.postArrAuditType.push(this.arrAuditTypeName[0])
        for (var i = 0; i < 8; i++) {
          if (item['__EMPTY_' + i]) this.postAarrArea.push(this.arrAreaName[i])
        }
        for (var e = 8; e < 71; e++) {
          if (item['__EMPTY_' + e])
            this.postArrAuditType.push(this.arrAuditTypeName[e - 7])
        }
        this.dataList.push({
          compName: item.compName,
          deptName: item.deptName,
          postName: item.postName,
          functionName: String(item.functionName),
          fileNo: String(item.fileNo),
          fileName: item.fileName ?  String(item.fileName) : '',
          interpretation: item.interpretation,
          question: item.question ?  String(item.question) : '',
          auditMethod: item.auditMethod ?  String(item.auditMethod) : '',
          answer: (item.answer ? item.answer : '') + '',
          examQuestion: item.examQuestion ? String(item.examQuestion) : '',
          disturbQuestion: item.disturbQuestion ? String(item.disturbQuestion) : '',
          choiceQue: item.choiceQue ? String(item.choiceQue) : '',
          choiceQueOption: String(item.choiceQueOption) ? item.choiceQueOption : '',
          choiceQueAnswer: String(item.choiceQueAnswer) ? item.choiceQueAnswer : '',
          riskName: item.riskName ? String(item.riskName) : '',
          riskPoint: item.riskPoint ? String(item.riskPoint) : '',
          riskResponse: item.riskResponse ? String(item.riskResponse) : '',
          version: item.version ? String(item.version) : '',
          module: item.module ? item.module : '',
          isCurrency: item.isCurrency ? 1 : 0,
          isFuncCurrency: item.isFuncCurrency ? 1 : 0,
          isSpecial: item.isSpecial ? 1 : 0,
          isRisk: item.isRisk ? 1 : 0,
          areaAudit: item.areaAudit ? 1 : 0,
          fileAudit: item.fileAudit ? 1 : 0,
          arrArea: String(this.postAarrArea),
          arrAuditType: String(this.postArrAuditType),
        })
      })
      this.excelName = excelName
    },
    surePost() {
      this.loading = true
      this.postBatchAdd(this.dataList, this.excelName)
    },
    async postBatchAdd(list, excelName) {
      await post('/api/QuesPoolAndExam/CheckData?ExcelName=' + excelName, {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '验证成功,开始导入',
            })
            this.dataList.map((i, index) => {
              this.importData(i, index)
            })
          } else {
            this.showError(resp.message)
          }
        })
        .catch(() => {
          this.loading = false
          this.$message.error('系统异常,请稍后再试')
        })
    },
    async importData(i, index) {
      await post('/api/QuesPoolAndExam/BatchAdd', i)
        .then((resp) => {
          if (resp.code == 200) {
            if (index + 1 == this.dataList.length) {
              this.loading = false
              this.$message({
                type: 'success',
                message: '已全部导入',
              })
            }
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.loading = false
          this.$message.error('系统异常,请稍后再试')
        })
    },
    showError(msg) {
      this.$confirm(msg, {
        confirmButtonText: '查看错误',
        cancelButtonText: '关闭',
        type: 'error',
      })
        .then(() => {
          get(
            '/api/QuesErrorlog/GetByUserId?UserId=' +
              JSON.parse(sessionStorage.getItem('userInfo')).id
          ).then((resp) => {
            if (resp.code == 200) {
              this.tableList2 = resp.data
            }
          })
          this.showErrorDialog = true
        })
        .catch(() => {
          location.reload()
        })
    },
    handleClose() {
      this.showErrorDialog = false
      location.reload()
    },
    clickText(e) {
      this.errorText = e
      this.showErrorText = true
    },
  },
}
</script>

<style lang="less" scoped>
._SelectHeader {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    padding-right: 20px;
  }
}
/deep/ .el-table .cell {
  line-height: 15px !important;
}
.contain-box {
  position: absolute;
  top: 50px;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #e8e9ee;
  width: 100%;
  > div {
    overflow: auto;
    border: 1px solid #dbdbdb;
    display: inline-block;
    padding-right: 20px;
  }
}
textarea {
  outline: none;
  border: 0px;
  width: 100%;
  height: 120px;
  resize: none;
}
.width1 {
  width: 119.2px;
}
.width2 {
  width: 39.2px;
}
/deep/ .el-dialog__wrapper {
  overflow: hidden !important;
}
.error-text {
  width: 100%;
  height: 400px;
  overflow: scroll;
}
</style>
<style>
.custom-tooltip-effect{
  max-width: 600px;
  line-height: 23px;
}
</style>